<template>
    <section class="obras-main px-3">
        <navbar-admin  />
        <tabs :tabs="tabsObras" />
        <router-view />
    </section>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return{
            titulo:'',
            tabsObras: [
                {
                    titulo:'Producción',
                    ruta:"obras.vigentes.cards",
                },
                {
                    titulo:'Finalizados',
                    ruta:"obras.vigentes.finalizadas",
                }
            ],
        }
    },
    watch:{
        "$route.name"(val){
            if(val){
                this.getBreadcumbs(['obras.main', val])
            }
        }
    },
    created(){
        this.getBreadcumbs([this.$route.name])
        this.spliceBreadcumbs([
            {
                position: 0,
                breadcumb: {
                    name:'Obras',
                    fs: `${this.FS}/proyectos/proceso/mosaico`,
                }
            }
        ])
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
        })
    },
}
</script>